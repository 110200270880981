import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ReactHelmet from "react-helmet";
import { HeaderSectionContainer } from "../components/Header";
import { PostPreview } from "../components/blog/PostPreview";
import { BlogPostMeta } from "../components/meta/BlogPostMeta";
import { SharePanel } from "../components/blog/SharePanel";
import { getAboutUs } from "../components/blog/AboutUs";
import { getPrimaryTag, getServiceTags } from "../components/blog/Utils";
import { FollowButton } from "../components/blog/FollowButton";
import { replaceHttpSrcWithHttps, replaceHttpWithHttps } from "../utils";
import Header from "src/elements/Header";
import Footer from "src/elements/Footer";
import theme from "src/components/theme";

const BlogPostContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 0;
  background-color: ${theme.titleColor};
  font-family: Georgia, "Times New Roman", Times, serif;

  -webkit-font-smoothing: antialiased;

  @media (min-width: ${theme.mobileBreakpoint}) {
    padding: 30px 0;
  }
`;

const MorePostsContainer = styled(BlogPostContainer)`
  background-color: ${theme.blogMainBackgroundColor};
`;

const BlogPostContent = styled.div`
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  max-width: ${theme.readableMaxWidth};
`;

const MorePostsContent = styled(BlogPostContent)`
  flex: initial;
  flex-flow: row wrap;
  overflow-x: visible;
  justify-content: space-between;
  align-items: stretch;
  list-style-type: none;
  max-width: 1100px;
`;

const Subtitle = styled.h2`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.subtitleColor};
  margin-top: 20px;
  text-transform: uppercase;
`;

const Title = styled.h1`
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 700;
  margin-top: 10px;
  color: ${theme.backgroundColor};
  text-align: center;
  max-width: ${theme.readableMaxWidth};
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${theme.mobileBreakpoint}) {
    font-size: 52px;
    line-height: 72px;
    padding: 0;
  }
`;

const TitleImage = styled.img`
  width: 100%;
  margin-top: 36px;
  max-height: 600px;
  max-width: 860px;
  flex: none;
  object-fit: cover;
  border-radius: 5px;
`;

const Article = styled.div`
  flex: auto;
  margin-top: 58px;
  width: 100%;
  color: ${theme.blogTextColor};
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  font-weight: 400;
  font-family: Georgia, serif;
  font-stretch: 100%;

  @media (min-width: ${theme.mobileBreakpoint}) {
    font-size: 22px;
    line-height: 36px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.blogTextColor};
    margin: 24px 0;
  }

  h2 {
    @media (max-width: ${theme.mobileBreakpoint}) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h3 {
    font-size: 22px;

    @media (min-width: ${theme.mobileBreakpoint}) {
      font-size: 30px;
    }
  }

  pre {
    border: solid 1px #dfe6ed;
    background-color: ${theme.blogMainBackgroundColor};
    white-space: pre-wrap;
    padding: 11px 20px;
    font-size: 14px;
  }

  img {
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
  }

  a {
    color: ${theme.blogTextColor};
    text-decoration: underline;
    font-size: inherit;
    font-weight: inherit;
  }

  p {
    font-style: normal;
    font-variant-caps: normal;
    color: #3c484e;
    font-family: Georgia, "Times New Roman", Times, serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
  }
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 64px;
  font-size: 12px;
  align-self: flex-start;

  b {
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.backgroundColor};
  }
`;

const AuthorAvatar = styled.img`
  display: inline-block;
  width: 56px;
  height: 56px;
  background: #e3e9ed;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
`;

const BlogPost = props => {
  const currentPost = props.data.currentPost;
  const previousPost = props.data.previousPost;
  const nextPost = props.data.nextPost;
  const serviceTags = getServiceTags(currentPost.tags);

  const aboutUsType = serviceTags["about"];

  const primaryTag = getPrimaryTag(currentPost.tags);

  return (
    <Layout header={null} skipMeta={true} footer={<Footer />}>
      <BlogPostMeta data={props.data.currentPost} />
      <ReactHelmet>
        <style type="text/css">{`${props.data.codeinjection_styles}`}</style>
        <link href="//cdnjs.cloudflare.com/ajax/libs/prism/1.6.0/themes/prism.min.css" rel="stylesheet" />
        <script type="text/javascript" src="//cdnjs.cloudflare.com/ajax/libs/prism/1.6.0/prism.min.js" />
        <script type="text/javascript" src="//cdnjs.cloudflare.com/ajax/libs/prism/1.6.0/components/prism-js.min.js" />
        <script
          type="text/javascript"
          src="//cdnjs.cloudflare.com/ajax/libs/prism/1.6.0/components/prism-swift.min.js"
        />
      </ReactHelmet>
      <HeaderSectionContainer>
        <Header />
      </HeaderSectionContainer>
      <BlogPostContainer itemScope itemType="http://schema.org/BlogPosting">
        <BlogPostContent>
          <Subtitle>
            {primaryTag && (
              <>
                <b>{primaryTag.name}</b>&nbsp;/&nbsp;
              </>
            )}
          </Subtitle>
          <Title itemProp="name">{currentPost.title}</Title>
        </BlogPostContent>
        {currentPost.feature_image && (
          <TitleImage src={replaceHttpWithHttps(currentPost.feature_image)} alt={currentPost.title} itemProp="image" className={"lazyload"} />
        )}
        <BlogPostContent>
          <Article
            dangerouslySetInnerHTML={{ __html: replaceHttpSrcWithHttps(currentPost.html) }}
            itemProp="articleBody"
          />
          {getAboutUs(aboutUsType)}
          <SharePanel title={currentPost.title} />
          <AuthorContainer>
            {currentPost.primary_author.profile_image && (
              <AuthorAvatar
                src={replaceHttpWithHttps(currentPost.primary_author.profile_image)}
                title={currentPost.primary_author.name}
              />
            )}
            <div itemProp="author" itemScope itemType="http://schema.org/Person">
              <b itemProp="name">{currentPost.primary_author.name}</b>
              <br />
              <FollowButton username={currentPost.primary_author.twitter} />
            </div>
          </AuthorContainer>
        </BlogPostContent>

        <meta itemProp="headline" content={currentPost.excerpt} />
        <meta itemProp="datePublished" content={currentPost.published_at} />
        <meta itemProp="dateModified" content={currentPost.published_at} />

        <span itemProp="publisher" itemScope itemType="http://schema.org/Organization">
          <meta itemProp="name" content="AppSpector" />
        </span>
      </BlogPostContainer>
      <MorePostsContainer>
        <MorePostsContent>
          {previousPost && <PostPreview post={previousPost} />}
          {nextPost && <PostPreview post={nextPost} />}
        </MorePostsContent>
      </MorePostsContainer>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    currentPost: PropTypes.object.isRequired,
    previousPost: PropTypes.object,
    nextPost: PropTypes.object,
    codeinjection_styles: PropTypes.string,
  }).isRequired,
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostQuery($pageId: String!, $previousPageId: String!, $nextPageId: String!) {
    currentPost: ghostPost(id: { eq: $pageId }) {
      ...GhostPostFields
    }
    previousPost: ghostPost(id: { eq: $previousPageId }) {
      ...GhostPostFields
    }
    nextPost: ghostPost(id: { eq: $nextPageId }) {
      ...GhostPostFields
    }
  }
`;
