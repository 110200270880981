import React from "react";
import styled from "styled-components";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import PropTypes from "prop-types";
import theme from "src/components/theme";

const SharePanelContainer = styled.div`
  display: flex;
  align-self: start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  > div {
    margin: 8px;
    cursor: pointer;
  }

  > p {
    color: ${theme.blogTextColor};
    font-style: normal;
    font-variant-caps: normal;
    font-weight: bold;
  }
`;

const iconSize = 32;

export const SharePanel = ({ title }) => {
  const url = global.document ? global.document.location.toString() : "";

  return (
    <SharePanelContainer>
      <p>Share:</p>
      <TwitterShareButton url={url} title={title} via={"AppSpector"}>
        <TwitterIcon size={iconSize} round />
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={iconSize} round />
      </FacebookShareButton>
      <RedditShareButton url={url} title={title}>
        <RedditIcon size={iconSize} round />
      </RedditShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={iconSize} round />
      </LinkedinShareButton>
      <EmailShareButton url={url} subject={title}>
        <EmailIcon size={iconSize} round />
      </EmailShareButton>
    </SharePanelContainer>
  );
};

SharePanel.propTypes = {
  title: PropTypes.string.isRequired,
};
