import * as React from "react";
import PropTypes from "prop-types";

export const FollowButton = ({ username }) => {
  if (!username || username === "") {
    return null;
  }

  return (
    <a
      className="twitter-follow-button"
      href={`https://twitter.com/${username}`}
      data-size="medium"
      data-show-count={false}
    >
      Follow @{username}
    </a>
  );
};

FollowButton.propTypes = {
  username: PropTypes.string,
};
