import styled from "styled-components";
import { AccentLinkButton } from "../Common";
import React from "react";
import { VideoPlayer } from "../index/VideoComponent";
import { graphql, useStaticQuery } from "gatsby";
import theme from "src/components/theme";

const ActionButton = styled(AccentLinkButton)`
  width: 200px;
  height: 46px;
  margin: 8px;
`;

const AboutUsActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px;
`;

const AboutUsContainer = styled.div`
  width: 100%;
  color: ${theme.blogTextColor};
  font-size: 22px;
  line-height: 36px;
  overflow: hidden;
  font-weight: 400;
  font-family: Georgia, serif;
  font-stretch: 100%;
  margin-bottom: 20px;

  video {
    margin-top: 20px;
    border-radius: 5px;
  }

  h1,
  h2 {
    color: ${theme.blogTextColor};
  }

  p {
    font-style: normal;
    font-variant-caps: normal;
    color: #3c484e;
    font-family: Georgia, "Times New Roman", Times, serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
`;

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query AboutUsDataQuery {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//data/components/index-header.md/" } }) {
        edges {
          node {
            frontmatter {
              video {
                previewImage {
                  publicURL
                }
                sources {
                  media
                  src {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const frontmatter = data.allMarkdownRemark.edges[0].node.frontmatter;

  const video = {
    previewImage: frontmatter.video.previewImage.publicURL,
    sources: frontmatter.video.sources.map(source => ({
      media: source.media,
      src: source.src.publicURL,
    })),
  };

  return (
    <AboutUsContainer>
      <hr />
      <h2>About Us</h2>
      <VideoPlayer video={video} />
      <p>
        AppSpector is remote debugging and introspection tool for iOS and Android applications. With AppSpector you can
        debug your app running in the same room or on another continent. You can measure app performance, view CoreData
        and SQLite content, logs, network requests and many more in realtime. Just like you we have been struggling for
        years trying to find stupid mistakes and dreaming of a better native tools, finally we decided to build them.
        This is the instrument that you’ve been looking for.
      </p>
      <AboutUsActions>
        <ActionButton href="https://app.appspector.com/register" target="_blank">
          Start Free Trial
        </ActionButton>
        <ActionButton href="https://demo.appspector.com" target="_blank">
          Open Live Demo
        </ActionButton>
      </AboutUsActions>
    </AboutUsContainer>
  );
};

export const getAboutUs = type => {
  switch (type) {
    case "none":
      return null;
    default:
      return <AboutUs />;
  }
};
