import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  box-shadow: 0 20px 90px 0 rgba(0, 0, 0, 0.5);
`;

const Video = styled.video`
  width: 100%;
`;

const getTypeForVideoFile = url => {
  if (url.endsWith(".mp4")) {
    return "video/mp4";
  }

  if (url.endsWith(".webm")) {
    return "video/webm";
  }
};

export const VideoShape = PropTypes.shape({
  previewImage: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    }),
  ).isRequired,
});

export const VideoPlayer = ({ video }) => {
  return (
    <Video id="main-video" controls autoPlay loop muted poster={video.previewImage} playsInline>
      {video.sources.map((source, index) => (
        <source key={`source${index}`} src={source.src} type={getTypeForVideoFile(source.src)} />
      ))}
    </Video>
  );
};

VideoPlayer.propTypes = {
  video: VideoShape.isRequired,
};

const VideoComponent = ({ video }) => {
  return (
    <VideoContainer>
      <VideoPlayer video={video} />
    </VideoContainer>
  );
};

VideoComponent.propTypes = {
  video: VideoShape.isRequired,
};

export default VideoComponent;
