import * as React from "react";
import ReactHelmet from "react-helmet";
import { ImageMeta } from "./ImageMeta";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import get from "lodash/get";

export const BlogPostMeta = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsArticleMeta {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
              }
            }
          }
        }
      `}
      render={settingsData => {
        const ghostPost = data;
        const settings = settingsData.allGhostSettings.edges[0].node;

        const shareImage = ghostPost.feature_image ? ghostPost.feature_image : get(settings, `cover_image`, null);
        const description = ghostPost.meta_description || ghostPost.excerpt || settings.description;
        const title = ghostPost.meta_title || ghostPost.title || settings.title;

        return (
          <>
            <ReactHelmet title={title}>
              <meta charSet="utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
              <meta name="description" content={ghostPost.meta_description || ghostPost.excerpt} />
              <meta property="og:type" content="article" />
              <meta property="og:title" content={ghostPost.og_title || title} />
              <meta property="og:description" content={ghostPost.og_description || description} />
              <meta property="article:published_time" content={ghostPost.published_at} />
              <meta property="article:modified_time" content={ghostPost.updated_at} />
              <meta name="twitter:title" content={ghostPost.twitter_title || title} />
              <meta name="twitter:description" content={ghostPost.twitter_description || description} />
            </ReactHelmet>
            <ImageMeta image={shareImage} />
          </>
        );
      }}
    />
  );
};

BlogPostMeta.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    meta_title: PropTypes.string,
    meta_description: PropTypes.string,
    primary_author: PropTypes.object.isRequired,
    feature_image: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        visibility: PropTypes.string,
      }),
    ),
    primaryTag: PropTypes.shape({
      name: PropTypes.string,
    }),
    og_title: PropTypes.string,
    og_description: PropTypes.string,
    twitter_title: PropTypes.string,
    twitter_description: PropTypes.string,
    excerpt: PropTypes.string.isRequired,
  }).isRequired,
};
